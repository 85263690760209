<template>
  <div>
    <div class="main">
      <v-row>
        <DialogConfirmation
          tag="edit"
          title="Alterar Dados"
          :show="exibirDialogAvisoEdicao"
          @confirm="alterarDadosDependente()"
          @close="exibirDialogAvisoEdicao = false"
        >
          <template v-slot:body>
            <div class="mt-5">
              <h3>
                Esta ação alterará os dados deste dependente.
                (Cerfique-se de ter a permissão do dependente ou titular para fazer esta alteração).
              </h3>
              <h3>
                Deseja continuar?
              </h3>
            </div>
          </template>
        </DialogConfirmation>

        <v-col offset-sm="12" md="12">
          <TitleH2 class="mt-3 mb-5 mx-3" title="Dados do Dependente" />
          <FormField
            ref="formDadosDependente"
            :fields="fields"
            :validate="keyValidacaoForm"
            @change="onFormChange($event)"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import utilService from '@/services/UtilService'
import associadoService from '@/services/AssociadoService'
import cepService from '@/services/CepService'
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import StatusChip from '@/components/atoms/StatusChip'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import globalMethods from "@/mixins/globalMethods.vue";

export default {
  components: {
    TitleH2,
    FormField,
    StatusChip,
    ButtonMain,
    DialogConfirmation
  },

  props: {
    dependente: {
      type: Object,
      default: {}
    },
    validar: {
      type: Boolean,
      required: true,
      default: false,
    }
  },

  mixins: [ globalMethods],

  data() {
    return {
      avatar: '',
      status: '',
      exibirDialogAvisoEdicao: false,
      criandoFormulario: false,
      keyValidacaoForm: 0,
      dadosColaborador: {},
      listaUfs: [],
      enderecoCep: [],
      validadores: {
        obrigatorio: 'Campo obrigatório',
        invalido: 'Campo inválido',
      },
      fields: {
        nome: {
          label: "Nome",
          valueField: null,
          value: '',
          cols: 6,
          field: "InputField",
          readonly: false,
          disabled: true,
          rules: [(value) => !!value || this.validadores.obrigatorio],
        },
        cpf: {
          label: "CPF",
          valueField: null,
          value: '',
          cols: 3,
          value: null,
          readonly: false,
          disabled: true,
          rules: [(value) => !!value || this.validadores.obrigatorio],
          field: "InputMaskField",
          maskFormat: "###.###.###-##",
        },
        dataNascimento: {
          label: "Data de Nascimento",
          valueField: null,
          value: '',
          cols: 3,
          field: "InputMaskField",
          maskFormat: "##/##/####",
          readonly: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],
        },
        email: {
          label: "Email",
          valueField: null,
          value: '',
          cols: 6,
          readonly: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],

          field: "InputField",
        },
        telefone: {
          label: "Telefone",
          valueField: null,
          value: '',
          value: null,
          cols: 3,
          readonly: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],

          field: "InputMaskField",
          maskFormat: "(##) #####-####",
        },
        idSexo: {
          label: 'Sexo',
          valueField: null,
          value: '',
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'descricao',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idCor: {
          label: 'Cor',
          valueField: null,
          value: '',
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'descricao',
          clearable: false,
          rules: []
        },
        profissao: {
          label: 'Profissão',
          valueField: null,
          value: '',
          cols: 5,
          field: 'InputField',
          key: 5,
          rules: []
        },
        idEstadoCivil: {
          label: 'Estado Civil',
          valueField: null,
          value: '',
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'descricao',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },

        filiacao: {
          cols: 12,
          label: "Filiação",
          field: "DividerMain",
        },
        mae: {
          label: "Nome da mãe",
          valueField: null,
          value: '',
          cols: 6,
          readonly: false,
          rules: [],
          field: "InputField",
        },
        cpfMae: {
          label: "CPF da Mãe",
          valueField: null,
          value: '',
          cols: 3,
          value: null,
          readonly: false,
          rules: [],
          field: "InputMaskField",
          maskFormat: "###.###.###-##",
        },

        enderecoResidencial: {
          cols: 12,
          label: "Endereço Residencial",
          field: "DividerMain",
        },
        cep: {
          label: "CEP",
          valueField: null,
          value: '',
          cols: 3,
          readonly: false,
          field: "InputMaskField",
          maskFormat: "#####-###",
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 8 ||
              this.validadores.invalido
          ]
        },
        logradouro: {
          label: "Logradouro",
          valueField: null,
          value: '',
          cols: 6,
          readonly: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],
          field: "InputField",
        },
        numero: {
          label: "Número",
          valueField: null,
          value: '',
          cols: 3,
          readonly: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],
          field: "InputField",
        },
        complemento: {
          label: "Complemento",
          valueField: null,
          value: '',
          cols: 3,
          readonly: false,
          rules: [],
          field: "InputField",
        },
        bairro: {
          label: "Bairro",
          valueField: null,
          value: '',
          cols: 3,
          readonly: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],
          field: "InputField",
        },
        idUfEndereco: {
          label: 'UF',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idCidade: {

          label: 'Cidade',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
      },
    }
  },

  created() {
    this.dadosDependente()

    this.preencherSelectsNoInicio(this.fields, [
      { metodo: utilService.ufs(), controle: 'idUfEndereco' },
      { metodo: utilService.sexos(), controle: 'idSexo' },
      { metodo: utilService.estadosCivis(), controle: 'idEstadoCivil' },
      { metodo: utilService.coresPessoa(), controle: 'idCor' },
    ])
  },

  methods: {
    preencherSelectsNoInicio(fields, chamadas) {
      chamadas.forEach(chamada => {
        chamada.metodo
          .then(({ data }) => {
            fields[chamada.controle].items = data

            if(chamada.controle === 'idUfEndereco') {
              this.listaUfs = data
            }
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          })
      })
    },

    dadosDependente() {
      associadoService
        .dependentePorId(this.dependente.id)
        .then(({ data }) => {
          this.popularFormulario(data)
        })
    },

    popularFormulario(data) {
      this.criandoFormulario = true
      Object.entries(data).map(([key, value]) => {
        if (this.fields[key]) {
          this.fields[key].valueField = value
        }
      })

      if(data.cidade) {
        this.popularLocalViaCidade(data.cidade, 'idUfEndereco', 'idCidade')
      }
    },

    popularLocalViaCidade(cidadeId, fieldUf, fieldCidade){
      utilService.cidadePorId(cidadeId)
        .then(({ data }) => {
          this.fields[fieldUf].valueField = data.uf.id

          utilService.cidadesPorUf(data.uf.id)
            .then(({ data }) => {
              this.fields[fieldCidade].items = data
              this.fields[fieldCidade].valueField = cidadeId
              this.fields[fieldCidade].value = cidadeId
            })
        })
    },

    receberCidadesPorUF(ufId, fieldCidade) {
      utilService.cidadesPorUf(ufId).then(({ data }) => {
        this.fields[fieldCidade].items = data
      })
    },

    alterarDadosDependente() {
      const camposIgnorar = ['ButtonMain', 'DividerMain']
      const body = {}
      Object.entries(this.fields).forEach(([key, value]) => {
        if(!camposIgnorar.some(campo => campo === this.fields[key].field)) {
          body[key] = value.value
        }
      })

      body['arquivos'] = []
      body['idParentesco'] = this.dependente?.idParentesco || null
      body['associado'] = this.dependente?.idAssociado || null
      body['dataNascimento'] = this.formatarData(body['dataNascimento'])

      this.$emit('formValue', body)
      this.exibirDialogAvisoEdicao = false
    },

    onFormChange(campo) {
      if (campo.variableName === 'idConselho') {
        this.fields.numeroConselho.valueField = ''
      }
    },

    formatarData(data) {
      return `${data.slice(0, 2)}/${data.slice(2, 4)}/${data.slice(4)}`
    },

    verificarCep(cep) {
      cepService.buscarCep(cep).then(({ data }) => {
        if (data.erro) {
          this.fields.bairro.valueField = null
          this.fields.logradouro.valueField = null
          this.fields.idUfEndereco.valueField = null
          this.fields.idUfEndereco.value = null
          this.fields.idCidade.valueField = null
          this.fields.idCidade.value = null
          return
        }

        this.fields.bairro.valueField = data.bairro
        this.fields.logradouro.valueField = data.logradouro
        this.enderecoCep = data
      })
    },
  },

  watch: {
    'fields.cep.value': function (cep) {
      if (this.criandoFormulario) {
        this.criandoFormulario = false
        return
      }

      if (cep.length === 8) {
        this.verificarCep(cep)
      }
    },

    'fields.idUfEndereco.value': function (id) {
      this.receberCidadesPorUF(id, 'idCidade')
    },

    enderecoCep: function (valor) {
      this.listaUfs.filter((uf) => {
        if (uf.sigla === valor.uf) {
          this.fields.idUfEndereco.valueField = uf.id
          this.fields.idUfEndereco.value = uf.id

          utilService.cidadesPorUf(uf.id).then(({ data }) => {
            this.fields.idCidade.items = data

            this.fields.idCidade.items.filter((cidade) => {
              if (
                cidade.nome ===
                valor.localidade
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toUpperCase()
              ) {
                this.fields.idCidade.value = cidade.id
                this.fields.idCidade.valueField = cidade.id
              }
            })
          })
        }
      })
    },

    validar: {
      immediate: false,
      handler(newValue) {
        if(newValue) {
          if (this.verificarSeHaCampoObrigatorioNulo(this.fields, this.validadores)) {
            ++this.keyValidacaoForm;
            this.$root.vtoast.show({
              status: 'error',
              title: 'Erro!',
              text: 'Dados Incompletos',
            });

            return
          }

          this.exibirDialogAvisoEdicao = true
        }
      }
    },
  }
}
</script>

<style scoped>
.footer-pagina {
  margin-top: 2rem;
}
</style>
