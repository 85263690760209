<template>
  <div>
    <v-tabs
      v-model="tab"
      align-with-title
    >
      <v-icon
        style="cursor: pointer"
        class="ml-5 mr-4"
        @click="$router.push('/lista-associados')"
        >mdi-keyboard-backspace</v-icon
      >

      <v-tabs-slider color="grey lighten-3"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.content">
          <v-container>
            <component class="mt-5" :is="item.component" />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosAssociado from "./DadosAssociado";
import ListaDependentes from "./ListaDependentes";
import Escritorios from "./Escritorios";

export default {
  components: {
    DadosAssociado,
    ListaDependentes,
    Escritorios,
  },
  data() {
    return {
      tab: null,
      items: [
        {
          tab: "Dados Pessoais",
          content: "dados-pessoais",
          component: "DadosAssociado",
        },
        {
          tab: "Dependentes",
          content: "lista-dependentes",
          component: "ListaDependentes",
        },
        {
          tab: "Endereço Profissional",
          content: "endereco-profissional",
          component: "Escritorios",
        },
      ],
    };
  },
};
</script>

<style></style>
