<template>
  <div>
    <v-dialog v-model="dialog" width="700">
      <v-card v-if="listaArquivos.length > 0" class="text-center">
        <v-card-title>Arquivos do Dependente</v-card-title>
        <v-divider></v-divider>

        <div v-for="(arquivo, index) in listaArquivos" :key="index">
          <div>
            <v-hover>
              <template v-slot:default>
                <v-card
                  elevation="2"
                  class="mx-auto pa-2 d-flex pl-5 pr-5 justify-space-between"
                >
                  <p>{{ arquivo.descricaoTipo }}</p>
                  <div>
                    <!-- <v-btn small text>
                      <a :href="arquivo.arquivo" target="_blank">Abrir </a>
                    </v-btn> -->
                    <a style="text-decoration: none"
                      :href="arquivo.arquivo"
                      target="_blank"
                    >ABRIR</a>
                  </div>
                </v-card>
              </template>
            </v-hover>
          </div>
        </div>

      </v-card>

      <v-card v-if="!listaArquivos.length" class="text-center">
        <v-card-title>Arquivos do Dependente</v-card-title>
        <v-divider></v-divider>
        <div>
          <v-hover>
            <template v-slot:default>
              <v-card elevation="2" class="mx-auto pa-2 text-center">
                <p class="mt-2">Nenhum arquivo encontrado</p>
              </v-card>
            </template>
          </v-hover>
        </div>
      </v-card>
    </v-dialog>

    <CrudTable
      tabelaKey="id"
      tabelaComDetalhes
      :itemsPerPage="100"
      :headers="headers"
      :items="dependentesAssociados"
      :pagination="pagination"
    >
      <template v-slot:[`item.acoes`]="{ item }">
        <v-row class="justify-center">
          <TooltipMain top text="Ver aquivos">
            <ButtonIcon
              class="mx-1"
              :primary="true"
              icon="folder-eye"
              @click.native.stop="abrirDialogArquivos(item.arquivos)"
            />
          </TooltipMain>

          <TooltipMain top text="Editar Dependente">
            <ButtonIcon
              class="mx-1"
              :warning="true"
              icon="pencil"
              @click.native="
                dependente = item;
                exibirDialogDependente = true
              "
            />
          </TooltipMain>
        </v-row>
      </template>

    </CrudTable>

    <DialogConfirmation
      persistente
      tag="edit"
      title="Editar Dependente"
      :show="exibirDialogDependente"
      :key="keyDialogDependente"
      :width="1440"
      :loading="editandoDependente"
      :scrollable="true"
      @confirm="validarForm()"
      @close="fechaDialogDependente()"
    >
      <template v-slot:body>
        <DadosDependente
          :dependente="dependente"
          :validar="validarFormDependente"
          @formValue="editarDependente($event)"
          @closeDialog="exibirDialogDependente = false; ++keyDialogDependente"
        />
      </template>
    </DialogConfirmation>
  </div>
</template>

<script>
import CrudTable from "../../components/molecules/tables/CrudTable";
import TooltipMain from '@/components/atoms/TooltipMain.vue'
import ButtonIcon from '@/components/atoms/buttons/ButtonIcon.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import DadosDependente from './DadosDependente.vue'

import api from "@/services/api";
import associadoService from "@/services/AssociadoService";

export default {
  components: {
    CrudTable,
    TooltipMain,
    ButtonIcon,
    DialogConfirmation,
    DadosDependente,
  },

  data() {
    return {
      dialog: false,
      keyDialogDependente: false,
      exibirDialogDependente: false,
      editandoDependente: false,
      validarFormDependente: false,

      dependente: {},

      dependentesAssociados: [],
      listaArquivos: [],
      headers: [
        { text: "Nome", value: "nome", sortable: false },
        { text: "CPF", align: "center", value: "cpf", sortable: false },
        {
          text: "	Data de Nascimento",
          align: "center",
          value: "dataNascimento",
          sortable: false,
        },
        {
          text: "Parentesco",
          align: "center",
          value: "descricaoParentesco",
          sortable: false,
        },
        {
          text: "Ações",
          align: "center",
          value: "acoes",
          sortable: false,
        },
      ],
      pagination: {
        page: 0,
        perPage: 10,
        total: 0,
      },
    };
  },

  created() {
    this.dependentesPorAssociado();
  },

  methods: {
    dependentesPorAssociado() {
      associadoService
        .dependentesPorAssociado(this.$route.params.id)
        .then(({ data }) => {
          this.dependentesAssociados = data;
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    abrirDialogArquivos(arquivos) {
      this.listaArquivos = arquivos;
      this.dialog = true;
    },

    fechaDialogDependente() {
      this.exibirDialogDependente = false;
      ++this.keyDialogDependente;
    },

    editarDependente(body){
      this.editandoDependente = true
      associadoService
        .atualizarDependente(this.dependente.id, body)
        .then(() => {
          this.editandoDependente = false
          this.exibirDialogDependente = false
          ++this.keyDialogDependente

          this.$root.vtoast.show({
            status: "success",
            text: "Dados atualizados.",
            title: "Sucesso!",
          });

          this.dependentesPorAssociado()
        })
        .catch((err) => {
          this.editandoDependente = false
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    validarForm() {
      this.validarFormDependente = true;
      setTimeout(() => this.validarFormDependente = false, 50)
    },
  },
};
</script>

<style></style>
