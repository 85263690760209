<template>
  <div>
    <div class="main">
      <v-row>
        <DialogConfirmation
          tag="edit"
          title="Alterar Dados"
          :show="exibirDialogAvisoEdicao"
          @confirm="alterarDadosAssociado()"
          @close="exibirDialogAvisoEdicao = false"
        >
          <template v-slot:body>
            <div class="mt-5">
              Esta ação alterará os dados deste associado. (Cerfique-se de ter a
              permissão do associado para fazer esta alteração).
            </div>
          </template>
        </DialogConfirmation>

        <v-col class="text-center" md="3" order-md="2">
          <v-avatar size="avatarSize" color="primary" rounded>
            <v-img height="250" width="250" :src="foto"></v-img>
          </v-avatar>

          <div v-if="status" class="text-center">
            <StatusChip class="mt-1" :color="getColor(status)">
              {{ status }}</StatusChip
            >
          </div>
        </v-col>
        <v-col offset-sm="12" md="9">
          <FormField :readonly="editar" :fields="dadosGerais" />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-end" offset-sm="12" md="9">
          <ButtonMain
            class="primary mr-5 mb-5"
            style="width: 12rem"
            animacao="spin"
            :text="alterandoDadosAssociado ? 'Alterando...' : 'Alterar'"
            :icone="alterandoDadosAssociado ? 'autorenew' : ''"
            :desabilitar="alterandoDadosAssociado"
            @click.native="exibirDialogAvisoEdicao = true"
          ></ButtonMain>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import StatusChip from '../../components/atoms/StatusChip'
import TitleH2 from '../../components/atoms/title/TitleH2'
import FormField from '@/components/atoms/forms/FormField.vue'
import ButtonMain from '../../components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '../../components/molecules/dialogs/DialogConfirmation.vue'

import api from '@/services/api'
import utilService from '../../services/UtilService'
import associadoService from '../../services/AssociadoService'
import cepService from '../../services/CepService'

export default {
  components: {
    StatusChip,
    TitleH2,
    FormField,
    ButtonMain,
    DialogConfirmation
  },

  data() {
    return {
      DetalhesApi: {},
      editar: true,
      alterandoDadosAssociado: false,
      exibirDialogAvisoEdicao: false,
      criandoFormulario: false,
      validacao: 0,
      status: '',
      readonly: true,
      dadosArry: [],
      listaUfs: [],
      enderecoCep: [],
      foto: '',
      validadores: {
        obrigatorio: 'Campo obrigatório',
        invalido: 'Campo inválido',
        numeroConselho: 'Campo obrigatório quando o Conselho for selecionado'
      },
      dadosAssociado: {},
      filiacao: [],
      enderecoResidencial: [],
      dadosGerais: {
        nome: {
          label: 'Nome',
          valueField: null,
          cols: 8,
          field: 'InputField',
          disabled: true,
          readonly: true
        },
        dataNascimento: {
          label: 'Data de Nascimento',
          valueField: null,
          value: '',
          cols: 4,
          field: 'InputField',
          disabled: true,
          readonly: true
        },
        idPaisNacionalidade: {
          label: 'Nacionalidade',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idUfNaturalidade: {
          label: 'Naturalidade UF',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idCidadeNaturalidade: {
          label: 'Naturalidade Município',
          valueField: null,
          value: '',
          cols: 6,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        numeroOab: {
          label: 'Nº OAB',
          valueField: null,
          value: '',
          cols: 3,
          readonly: true,
          disabled: true,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idUfSubsecao: {
          label: 'Seção (UF)',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idCidadeSubsecao: {
          label: 'Subseção',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        cpf: {
          label: 'CPF',
          valueField: null,
          value: '',
          cols: 3,
          value: null,
          readonly: true,
          disabled: true,
          field: 'InputMaskField',
          maskFormat: '###.###.###-##'
        },
        email: {
          label: 'Email',
          valueField: null,
          value: '',
          cols: 6,
          readonly: true,
          disabled: true,
          field: 'InputField'
        },
        ddd: {
          label: 'DDD',
          valueField: null,
          value: '',
          cols: 2,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        telefone: {
          label: 'Telefone ',
          valueField: null,
          value: '',
          cols: 4,
          field: 'InputMaskField',
          maskFormat: '#####-####',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idSexo: {
          label: 'Sexo',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'descricao',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idCor: {
          label: 'Cor',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'descricao',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idEstadoCivil: {
          label: 'Estado Civil',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'descricao',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        profissao: {
          label: 'Profissão',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        dataAdesao: {
          label: 'Data de Inscrição OAB',
          valueField: null,
          value: '',
          cols: 4,
          field: 'InputField',
          disabled: true,
          readonly: true
        },
        filiacao: {
          cols: 12,
          label: 'Filiação',
          field: 'DividerMain',
          readonly: true
        },
        mae: {
          label: 'Nome da mãe',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField'
        },
        cpfMae: {
          label: 'CPF da mãe',
          valueField: null,
          cols: 6,
          value: '',
          field: 'InputMaskField',
          maskFormat: '###.###.###-##',
          readonly: true,
          disabled: true
        },
        pai: {
          label: 'Nome do pai',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField'
        },
        enderecoResidencial: {
          cols: 12,
          label: 'Endereço Residencial',
          field: 'DividerMain'
        },
        cep: {
          label: 'CEP',
          valueField: null,
          value: '',
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '#####-###',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 8 ||
              this.validadores.invalido
          ]
        },
        logradouro: {
          label: 'Logradouro',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField'
        },
        numero: {
          label: 'Número',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        complemento: {
          label: 'Complemento',
          valueField: null,
          value: '',
          cols: 4,
          field: 'InputField'
        },
        bairro: {
          label: 'Bairro',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputField'
        },
        idUfEndereco: {
          label: 'UF',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idCidadeEndereco: {
          label: 'Cidade',
          valueField: null,
          value: '',
          value: '',
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        }
      }
    }
  },

  methods: {
    pegarUf() {
      api.getUf().then(({ data }) => {
        this.listaUfs = data
        this.dadosGerais.idUfSubsecao.items = data
        this.dadosGerais.idUfNaturalidade.items = data
        this.dadosGerais.idUfEndereco.items = data
      })
    },

    receberPaises() {
      utilService.paises().then(({ data }) => {
        this.dadosGerais.idPaisNacionalidade.items = data
      })
    },

    receberCidadesPorUF(ufId, fieldCidade) {
      utilService.cidadesPorUf(ufId).then(({ data }) => {
        this.dadosGerais[fieldCidade].items = data
      })
    },

    getListaSexos() {
      utilService.sexos().then(({ data }) => {
        this.dadosGerais.idSexo.items = data
      })
    },

    getListaCores() {
      utilService.coresPessoa().then(({ data }) => {
        this.dadosGerais.idCor.items = data
      })
    },

    getListaEstadosCivis() {
      utilService.estadosCivis().then(({ data }) => {
        this.dadosGerais.idEstadoCivil.items = data
      })
    },

    getColor(status) {
      if (status === 'IRREGULAR') return 'red'
      else if (status === 'EM ANALISE') return 'orange'
      else if (status === 'REGULAR') return 'green'
      else return ''
    },

    detalhesAssociado() {
      api.getDetalheAssociado(this.$route.params.id).then(({ data }) => {
        this.status = data.status
        this.dadosAssociado = data
        this.foto = !data.urlFotoPerfil
          ? `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${data.nome}`
          : data.urlFotoPerfil

        this.montar(data)
      })
    },
    montar(data) {
      Object.entries(data).map(([key, value]) => {
        if (this.dadosGerais[key]) {
          this.dadosGerais[key].valueField = value
        }
      })
    },

    validarCamposFormulario(campos) {
      let campoInvalido = false
      for (const [key, value] of Object.entries(campos)) {
        const valorCampo = value.value
        const regras = value.rules
        let erroCampo = false

        if (regras && regras.length) {
          for (const regra of regras) {
            if (
              regra(valorCampo) === this.validadores.obrigatorio ||
              regra(valorCampo) === this.validadores.invalido ||
              regra(valorCampo) === this.validadores.numeroConselho
            ) {
              erroCampo = true
              break
            }
          }
        }

        if (erroCampo) {
          campoInvalido = true
          break
        }
      }

      return campoInvalido
    },

    alterarDadosAssociado() {
      this.exibirDialogAvisoEdicao = false

      if (this.validarCamposFormulario(this.dadosGerais)) {
        ++this.validacao
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Dados Incompletos'
        })

        return
      }

      this.alterandoDadosAssociado = true
      const body = {
        nome: this.dadosGerais.nome.value,
        cpf: this.dadosGerais.cpf.value,

        cidadeNaturalidade: this.dadosGerais.idCidadeNaturalidade.value,
        cidadeSubsessao: this.dadosGerais.idCidadeSubsecao.value,
        dataNascimento: this.dadosGerais.dataNascimento.value,
        profissao: this.dadosGerais.profissao.value,
        sexo: this.dadosGerais.idSexo.value,
        cor: this.dadosGerais.idCor.value,
        estadoCivil: this.dadosGerais.idEstadoCivil.value,
        numeroOab: this.dadosGerais.numeroOab.value,
        paisNacionalidade: this.dadosGerais.idPaisNacionalidade.value,

        mae: this.dadosGerais.mae.value,
        cpfMae: this.dadosGerais.cpfMae.value,
        pai: this.dadosGerais.pai.value,

        email: this.dadosGerais.email.value,
        ddd: this.dadosGerais.ddd.value,
        telefone: this.dadosGerais.telefone.value,

        bairro: this.dadosGerais.bairro.value,
        cep: this.dadosGerais.cep.value,
        logradouro: this.dadosGerais.logradouro.value,
        complemento: this.dadosGerais.complemento.value,
        idCidadeEndereco: this.dadosGerais.idCidadeEndereco.value,
        idUfEndereco: this.dadosGerais.idUfEndereco.value,
        numero: this.dadosGerais.numero.value
      }

      associadoService
        .atualizarAssociado(body, this.$route.params.id)
        .then(() => {
          this.detalhesAssociado()
          this.$root.vtoast.show({
            status: 'success',
            title: 'Sucesso!',
            text: 'Colaborador atualizado com sucesso.'
          })
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
        .then(() => (this.alterandoDadosAssociado = false))
    },

    verificarCep(cep) {
      cepService.buscarCep(cep).then(({ data }) => {
        if (data.erro) {
          this.dadosGerais.bairro.valueField = null
          this.dadosGerais.logradouro.valueField = null
          this.dadosGerais.idUfEndereco.valueField = null
          this.dadosGerais.idCidadeEndereco.valueField = null
          return
        }

        this.dadosGerais.bairro.valueField = data.bairro
        this.dadosGerais.logradouro.valueField = data.logradouro
        this.enderecoCep = data
      })
    }
  },

  watch: {
    'dadosGerais.idUfNaturalidade.value': function (id) {
      this.receberCidadesPorUF(id, 'idCidadeNaturalidade')
    },

    'dadosGerais.idUfSubsecao.value': function (id) {
      this.receberCidadesPorUF(id, 'idCidadeSubsecao')
    },

    'dadosGerais.idUfEndereco.value': function (id) {
      this.receberCidadesPorUF(id, 'idCidadeEndereco')
    },

    'dadosGerais.cep.value': function (cep) {
      if (this.criandoFormulario) {
        this.criandoFormulario = false
        return
      }

      if (cep.length === 8) {
        this.verificarCep(cep)
      }
    },

    enderecoCep: function (valor) {
      this.listaUfs.filter((uf) => {
        if (uf.sigla === valor.uf) {
          this.dadosGerais.idUfEndereco.valueField = uf.id

          utilService.cidadesPorUf(uf.id).then(({ data }) => {
            this.dadosGerais.idCidadeEndereco.items = data

            this.dadosGerais.idCidadeEndereco.items.filter((cidade) => {
              if (
                cidade.nome ===
                valor.localidade
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toUpperCase()
              ) {
                this.dadosGerais.idCidadeEndereco.valueField = cidade.id
              }
            })
          })
        }
      })
    }
  },

  created() {
    this.pegarUf()
    this.receberPaises()
    this.getListaSexos()
    this.getListaEstadosCivis()
    this.getListaCores()
    this.detalhesAssociado()
  }
}
</script>

<style scoped></style>
