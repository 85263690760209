<template>
  <div>
    <DialogCard :show="dialog" @close="dialog = false" title="Detalhamento">
      <template v-slot:body>
        <FormField :fields="fields" />
      </template>
    </DialogCard>

    <CrudTable
      :headers="headers"
      :items="escritoriosAssociados"
      :pagination="pagination"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn text color="blue" @click="detailAdress(item)">
          ver detalhes
        </v-btn>
      </template>

      <template v-slot:[`item.cep`]="{ item }">
        <span>{{ item.cep | VMask("#####-###") }}</span>
      </template>
    </CrudTable>
  </div>
</template>

<script>
import api from "@/services/api";
import FormField from "@/components/atoms/forms/FormField";
import DialogConfirmation from "@/components/molecules/dialogs/DialogConfirmation";
import CrudTable from "@/components/molecules/tables/CrudTable";
import DialogCard from "@/components/molecules/dialogs/DialogCard";

export default {
  data() {
    return {
      dialog: false,
      escritoriosAssociados: [],
      headers: [
        { text: "Logradouro", value: "logradouro", sortable: false },
        {
          text: "	Bairro",
          align: "center",
          value: "bairro",
          sortable: false,
        },
        { text: "Cidade", value: "nomeCidadeProfissional", sortable: false },
        { text: "Cep", value: "cep", sortable: false },
        { text: "", align: "center", value: "action", sortable: false },
      ],
      pagination: {
        page: 0,
        perPage: 100,
        total: 0,
      },
      fields: {
        cep: {
          label: "CEP",
          valueField: null,
          readonly: true,
          cols: 3,
          readonly: true,
          field: "InputMaskField",
          maskFormat: "#####-###",
        },
        logradouro: {
          label: "Logradouro",
          valueField: null,
          readonly: true,
          cols: 6,
          readonly: true,
          field: "InputField",
        },
        numero: {
          label: "Número",
          valueField: null,
          readonly: true,
          cols: 3,
          readonly: true,
          field: "InputField",
        },
        complemento: {
          label: "Complemento",
          valueField: null,
          readonly: true,
          cols: 3,
          readonly: true,
          field: "InputField",
        },
        bairro: {
          label: "Bairro",
          valueField: null,
          readonly: true,
          cols: 3,
          readonly: true,
          field: "InputField",
        },
        siglaUfProfissional: {
          label: "UF",
          valueField: null,
          readonly: true,
          cols: 2,
          readonly: true,
          field: "InputField",
        },
        nomeCidadeProfissional: {
          label: "Cidade",
          valueField: null,
          readonly: true,
          cols: 4,
          readonly: true,
          field: "InputField",
        },
        enderecoProfissional: {
          cols: 12,
          label: "Contato",
          field: "DividerMain",
        },
        email: {
          label: "Email",
          valueField: null,
          readonly: true,
          cols: 5,
          readonly: true,
          field: "InputField",
        },
        ddd: {
          label: "DDD",
          valueField: null,
          readonly: true,
          cols: 3,
          readonly: true,
          field: "InputField",
        },
        telefone: {
          label: "Telefone",
          valueField: null,
          readonly: true,
          cols: 4,
          readonly: true,
          field: "InputField",
        },
        // ddd: '98',
        // idAssociado: 44,
        // idCidadeProfissional: null,
        // nomeCidadeProfissional: null,
        // idUfProfissional: null,
        // siglaUfProfissional: null,
      },
    };
  },
  components: {
    DialogConfirmation,
    CrudTable,
    FormField,
    DialogCard,
  },
  methods: {
    pegarApi() {
      api.getEscritorioAssociado(this.$route.params.id).then(({ data }) => {
        this.escritoriosAssociados = data;
      });
    },
    detailAdress(completeAddress) {
      this.dialog = true;

      Object.entries(completeAddress).map(([key, value]) => {
        if (this.fields[key]) {
          this.fields[key].valueField = value;
        }
      });
    },
  },
  created() {
    this.pegarApi();
  },
};
</script>

<style></style>
